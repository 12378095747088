import React from "react";

export const PrivacyPolicyConfig = {
  routes: [
    {
      path: "/privacy-policy",
      component: React.lazy(() => import("./PrivacyPolicy")),
    },
    {
      path: "/privacy-policy-french",
      component: React.lazy(() => import("./PrivacyPolicyFrench")),
    },
  ],
};
