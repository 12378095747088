import { createReducer } from "redux-act";
import {
  setPaymentInfo,
  resetInfo,
  userDetails,
  paymentSuccessResponse,
} from "modules/actions";

export type IPaymentReducer = {
  paymentInfo: any;
  userInfo: any;
  getpaymentSuccess: any;
};

const initialState: IPaymentReducer = {
  paymentInfo: null,
  userInfo: {},
  getpaymentSuccess: Boolean,
};

export const payment = createReducer<IPaymentReducer>({}, initialState);

payment.on(setPaymentInfo, (state: IPaymentReducer, payload: any) => {
  return {
    ...state,
    paymentInfo: payload,
  };
});

payment.on(resetInfo, () => initialState);

payment.on(userDetails, (state: IPaymentReducer, payload: any) => {
  console.log("reducer payment form data::::::::::",payload)
  return {
    ...state,
    userInfo: payload,
  };
});

payment.on(paymentSuccessResponse, (state: IPaymentReducer, payload: any) => {
  return {
    ...state,
    getpaymentSuccess: payload,
  };
});
