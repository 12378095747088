import React, { useEffect, Suspense } from "react";
import Router from "./router";
import { useAppContext } from "context/AppContext";
import DesktopRoutes from "router/routes";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "modules/actions";
import * as selector from "modules/selectors";
import DomainNotExist from "./pages/DomainNotExist/DomainNotExist";
import PleaseWait from "./pages/PleaseWait/PleaseWait";
import Footer from "pages/Footer/Footer"

const RenderLayout = () => {
  const { setRoutes } = useAppContext();
  const dispatch = useDispatch();
  const isDomainValid = useSelector(selector.isDomainValid);

  useEffect(() => {
    setRoutes(DesktopRoutes);
  }, [setRoutes]);

  useEffect(() => {
    if (!isDomainValid) dispatch(actions.checkDomain());
  }, [dispatch, isDomainValid]);

  return (
    <React.Fragment>
      <Suspense fallback={<PleaseWait />}>
        {isDomainValid && <Router />}
        {isDomainValid === undefined && <PleaseWait />}
        {isDomainValid === false && <DomainNotExist />}
        <ToastContainer />
      </Suspense>
      <Footer />
    </React.Fragment>
  );
};
export default RenderLayout;
