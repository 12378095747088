import { createReducer } from "redux-act";
import {
  changeDisputeModalData,
  defaultModalData,
  toggleDisputeModal,
  toggleTicketDetailsModal,
} from "modules/actions";
export type IDisputeModalReducer = {
  showDisputeModal: boolean;
  showTicketDetailsModal: boolean;
  modalData: {
    name: string | undefined;
    email: string | undefined;
    country_code: string | undefined;
    phone: string | undefined;
    address: string | undefined;
    reason: string | undefined;
  };
};

const initialState: IDisputeModalReducer = {
  showDisputeModal: false,
  showTicketDetailsModal: false,
  modalData: {
    name: undefined,
    email: undefined,
    country_code: undefined,
    phone: undefined,
    address: undefined,
    reason: undefined,
  },
};

export const disputeModal = createReducer<IDisputeModalReducer>(
  {},
  initialState
);
disputeModal.on(toggleDisputeModal, (state: IDisputeModalReducer) => ({
  ...state,
  showDisputeModal: !state.showDisputeModal,
}));
disputeModal.on(
  changeDisputeModalData,
  (state: IDisputeModalReducer, payload: any) => ({
    ...state,
    modalData: {
      ...state.modalData,
      ...payload,
    },
  })
);

disputeModal.on(defaultModalData, (state: IDisputeModalReducer) => ({
  ...state,
  showDisputeModal: false,
  modalData: {
    name: undefined,
    email: undefined,
    country_code: undefined,
    phone: undefined,
    address: undefined,
    reason: undefined,
  },
}));

disputeModal.on(
  toggleTicketDetailsModal,
  (state: IDisputeModalReducer, payload: boolean) => ({
    ...state,
    showTicketDetailsModal: payload,
  })
);
