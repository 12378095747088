import React from "react";

export const TicketsDetailsConfig = {
  routes: [
    {
      path: "/ticket-details/:lpn/:ticketno",
      component: React.lazy(() => import("./TicketDetails")),
    },
    {
      path: "/td/:search",
      component: React.lazy(() => import("./TicketDetails")),
    },
  ],
};
