import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store, { persistor } from "modules/store";
import registerServiceWorker from "./serviceWorker";
import { PersistGate } from "redux-persist/integration/react";
import { AppProvider } from "context/AppContext";
import RenderLayout from "./RenderLayout";
import GlobalStylesheet from "./GlobalStylesheet";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";

if (process.env.REACT_APP_ENV === "live" || process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "ca") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <AppProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename={process.env.REACT_APP_BASE_URL}>
          <GlobalStylesheet />
          <RenderLayout />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </AppProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
registerServiceWorker();
