import { call, put, select } from "redux-saga/effects";
import { ISagaAction } from "../types";
import { SagaIterator } from "redux-saga";
import {
  completeTicketPayment,
  doTicketPayment,
  //  updatePaymentStatus,
} from "../utils/Api/payment";
import { toast } from "react-toastify";
import {
  doATicketPayment,
  setPaymentInfo,
  paymentSuccessResponse,
  ticketDetailsFailed,
} from "../actions";
import { getPaymentInfo, getUserInfo, getTicketData } from "../selectors";

export const ticketPayment = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const { ticketId, fee, history, isGoBack, lpn, ticketno } = action.payload;
    const ticketData = yield select(getTicketData);
    const redirect_url = `${window.location.origin}/payment/step2/${ticketData[0].lpn}/${ticketId}`;

    const response = yield call(doTicketPayment, {
      tokenId: ticketId,
      search: lpn,
      ticketno,
      fee,
      redirect_url,
    });
    const successResult = response.result;
    const successResultText = response["result-text"];
    const transactionId = response["transaction-id"];
    const formUrl = response["form-url"];
    const resultCode = response["result-code"];
    yield put(
      setPaymentInfo({
        transactionId,
        result: successResult,
        resultText: successResultText,
        formUrl,
        resultCode,
      })
    );

    if (!isGoBack) {
      if (successResult === 1 && successResultText === "Step 1 completed") {
        history.replace(
          `/payment/step1/${ticketData[0].lpn}/${ticketData[0].ticket_no}/${ticketId}`
        );
      } else {
        toast.error(successResultText);
      }
    } else {
      history.goBack();
    }
  } catch (error) {
    console.log("ERROR:", error);
    yield put(ticketDetailsFailed());
  }
};

export const paymentCompleted = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const { history, ticketid, search } = action.payload;
    const paymentInfo = yield select(getPaymentInfo);
    const userDetails = yield select(getUserInfo);
    // const ticketData = yield select(getTicketData);
    // if (ticketData.length > 0) {
    //   threeCharLpn = ticketData[0].slice(0, 2);
    // }
    console.log("userDetailsssss", userDetails, ticketid);
    const response = yield call(
      completeTicketPayment,
      paymentInfo.token,
      userDetails,
      ticketid
    );
    const successResult = response.result;
    const successResultText = response["result-text"];

    if (successResult === 1) {
      // const paidAmount = response.amount;
      // yield call(updatePaymentStatus, ticketid, paidAmount);
      // history.replace(`/payment/step3/${search}/${ticketid}`);
      yield put(
        paymentSuccessResponse({
          response: true,
        })
      );
    } else if (successResult === 2 || successResult === 3) {
      toast.error(successResultText);
      history.replace(`/ticket-details/${search}/${userDetails.ticketno}`);
      if (successResult === 2) {
        const amount = response.amount;
        yield put(
          doATicketPayment({
            history,
            search,
            fee: amount,
            bypassNavigation: true,
            ticketId: ticketid,
          })
        );
      } else
        history.replace(`/ticket-details/${search}/${userDetails.ticketno}`);
    } else toast.error(successResultText);
  } catch (error) {
    console.log("ERROR:", error);
    yield put(ticketDetailsFailed());
  }
};
