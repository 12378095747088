import { IStoreState } from "../types";

export const getTicketDisputeModalState = (state: IStoreState) =>
  state.disputeModal.showDisputeModal;

export const getTicketDisputeModalData = (state: IStoreState) =>
  state.disputeModal.modalData;

export const getTicketDetailsModalState = (state: IStoreState) =>
  state.disputeModal.showTicketDetailsModal;
