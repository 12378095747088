import { createAction } from "redux-act";

export const toggleDisputeModal = createAction("show/hide dispute modal");
export const toggleTicketDetailsModal = createAction<boolean>("show/hide ticket details modal");

export const changeDisputeModalData = createAction<any>(
  "set dispute modal data"
);
export const defaultModalData = createAction("set default modal Data");
export const submitDisputeData = createAction<{ history: any }>(
  "submit dispute modal data"
);
export const setTicketDispute = createAction<boolean>(
  "set ticket dispute status"
);