import React from "react";

export const PaymentConfig = {
  routes: [
    {
      path: "/payment/step1/:search/:ticketno/:ticketid",
      component: React.lazy(() => import("./TicketPaymentStep1")),
    },
    {
      path: "/payment/step2/:search/:ticketid",
      component: React.lazy(() => import("./TicketPaymentSuccess")),
    },
    {
      path: "/payment/step3/:search/:ticketid",
      component: React.lazy(() => import("./TicketPaymentStep2")),
    },
  ],
};
