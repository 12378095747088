import { createReducer } from "redux-act";
import { selectedLanguage } from "modules/actions";
export type ILanguageReducer = {
  selectLanguage: any;
};

const initialState: ILanguageReducer = {
  selectLanguage: "",
};

export const language = createReducer<ILanguageReducer>({}, initialState);

language.on(selectedLanguage, (state: ILanguageReducer, payload: any) => {
  return {
    ...state,
    selectLanguage: payload,
  };
});
