import { createReducer } from "redux-act";
import {
  fetchTicketDetails,
  setTicketDetails,
  ticketDetailsFailed,
  setTicketNumber,
  getFooterData,
  setFooterDetails,
  setTicketDispute,
  setPaymentInfoDetails,
  setLanguageDetails
} from "modules/actions";
export type ITicketDetailsReducer = {
  ticketData: any[];
  languageData: any;
  fetching: boolean;
  disputeStatus: boolean;
  ticketNumber: number;
  ticketChat: any[];
  footerData: any;
  isPayment: true;
  paymentReceiptData: any;
};

const initialState: ITicketDetailsReducer = {
  ticketData: [],
  languageData:{},
  fetching: false,
  disputeStatus: false,
  ticketNumber: 0,
  ticketChat: [],
  footerData: {},
  isPayment: true,
  paymentReceiptData: "",
};

export const ticketDetails = createReducer<ITicketDetailsReducer>(
  {},
  initialState
);

ticketDetails.on(fetchTicketDetails, (state: ITicketDetailsReducer) => {
  return {
    ...state,
    fetching: true,
  };
});
ticketDetails.on(getFooterData, (state: ITicketDetailsReducer) => {
  return {
    ...state,
    fetching: true,
  };
});

ticketDetails.on(
  setFooterDetails,
  (state: ITicketDetailsReducer, payload: any[]) => {
    return {
      ...state,
      footerData: payload,
      fetching: false,
    };
  }
);
ticketDetails.on(
  setTicketDispute,
  (state: ITicketDetailsReducer, payload: boolean) => {
    return {
      ...state,
      disputeStatus: payload,
    };
  }
);
ticketDetails.on(
  setTicketDetails,
  (state: ITicketDetailsReducer, payload: any[]) => {
    return {
      ...state,
      ticketData: payload,
      fetching: false,
    };
  }
);

ticketDetails.on(
  setLanguageDetails,
  (state: ITicketDetailsReducer, payload: any[]) => {
    return {
      ...state,
      languageData: payload,
      fetching: false,
    };
  }
);

ticketDetails.on(
  setPaymentInfoDetails,
  (state: ITicketDetailsReducer, payload: any[]) => {
    return {
      ...state,
      paymentReceiptData: payload,
      fetching: false,
    };
  }
);

ticketDetails.on(ticketDetailsFailed, (state: ITicketDetailsReducer) => {
  return {
    ...state,
    fetching: false,
  };
});

ticketDetails.on(
  setTicketNumber,
  (state: ITicketDetailsReducer, payload: number) => {
    return {
      ...state,
      ticketNumber: payload,
    };
  }
);
