import { SagaIterator } from "redux-saga";
import { call, put } from "redux-saga/effects";
import { checkDomainExist } from "../utils/Api/domainCheck";
import { setDomainValid } from "../actions";

export const searchTicketData = function* (): SagaIterator {
  try {
    const response = yield call(checkDomainExist);
    yield put(setDomainValid(response.success === 1));
  } catch (error) {
    console.log("ERROR:", error);
    // here we will need to show a toaster to show an error
  }
};
