import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Translation } from "react-i18next";
import { getselectLanguage } from "modules/selectors";
import _i18n from "../TicketSearch/i18n";
import { useSelector } from "react-redux";

const DomainNotExist = () => {
  const lang = useSelector(getselectLanguage);
  useEffect(() => {
    _i18n.changeLanguage(lang);
  }, [lang]);
  return (
    <div className="login-container">
      <div className="login-card">
        <Card>
          <h2 className="title text-center">
            <span>
              <Translation>{(t) => <>{t("Enforcement.Zone")}</>}</Translation>
            </span>
            <br />
            <span>
              {" "}
              <Translation>
                {(t) => <>{t("Parking.Management.System")}</>}
              </Translation>
            </span>
          </h2>
          <Row>
            <Col md="12">
              <div className="info-container">
                <p>
                  <span>
                    <Translation>
                      {(t) => <>{t("domainNotExits_description")}</>}
                    </Translation>
                  </span>
                </p>
                <p className="mt-4">
                  <span>
                    <Translation>
                      {(t) => <>{t("domainNotExits_description2")}</>}
                    </Translation>
                    <Translation>
                      {(t) => <b>{t("enforcement.zone")}</b>}
                    </Translation>
                    <span>
                      <br />
                      <Translation>{(t) => <b>{t("ie")}</b>}</Translation>{" "}
                      <Translation>
                        {(t) => <b>{t("site.enforcement.zone")}</b>}
                      </Translation>
                    </span>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
export default DomainNotExist;
const Card = styled.div`
  width: 100%;
`;
