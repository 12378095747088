import React from "react";

export const TicketsSearchConfig = {
  routes: [
    {
      path: "/",
      component: React.lazy(() => import("./TicketSearch")),
    },
  ],
};
