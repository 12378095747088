import { SagaIterator } from "redux-saga";
import {
  getSelectedTicketNumber,
  getTicketData,
  getTicketDisputeModalData,
} from "../selectors";
import { call, select } from "redux-saga/effects";
import { submitDisputeForm } from "../utils/Api/disputeForm";
import { ISagaAction } from "../types";
import { toast } from "react-toastify";

export const submitDisputeDetails = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const history = action.payload.history;
    const data = yield select(getTicketDisputeModalData);
    const allTickets = yield select(getTicketData);
    const selectedTicketNo = yield select(getSelectedTicketNumber);
    const ticket = allTickets.find(
      (a: { ticket_no: number }) => a.ticket_no === selectedTicketNo
    );
    const form_data = {
      ticket_id: ticket.id,
      ...data,
    };
    const responseData = yield call(submitDisputeForm, form_data);
    if (responseData && responseData.success)
      history.push(`/dispute/${selectedTicketNo}`);
    else toast.error(responseData.message);
    console.log("action perfomed", responseData);
  } catch (error) {
    console.log("ERROR:", error);
  }
};
