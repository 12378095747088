import { SagaIterator } from "redux-saga";
import { takeLatest } from "redux-saga/effects";
import * as actions from "modules/actions";
import * as ticketDetails from "modules/sagas/ticketDetails";
import * as disputeModal from "modules/sagas/disputeModal";
import * as payment from "modules/sagas/payment";
import * as domainCheck from "modules/sagas/domainCheck";

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
  yield takeLatest(actions.fetchTicketDetails, ticketDetails.searchTicketData);
  yield takeLatest(actions.getPaymentInfoData, ticketDetails.getPaymentData);
  yield takeLatest(actions.sendPaymentMail, ticketDetails.sendPaymentMailCall);
  yield takeLatest(
    actions.sendDisputeMessage,
    ticketDetails.sendTicketChatDetails
  );
  yield takeLatest(actions.doATicketPayment, payment.ticketPayment);
  yield takeLatest(actions.ticketPaymentCompleted, payment.paymentCompleted);
  yield takeLatest(actions.ticketDispute, ticketDetails.ticketDispute_);
  yield takeLatest(actions.checkDomain, domainCheck.searchTicketData);
  yield takeLatest(
    actions.submitDisputeData,
    disputeModal.submitDisputeDetails
  );
  yield takeLatest(actions.getFooterData, ticketDetails.getFooterData);
}
