import { createReducer } from "redux-act";
import { setDomainValid } from "modules/actions";
export type IDomainCheckReducer = {
  isDomainValid: boolean | undefined;
};

const initialState: IDomainCheckReducer = {
  isDomainValid: undefined,
};

export const domainCheck = createReducer<IDomainCheckReducer>({}, initialState);
domainCheck.on(
  setDomainValid,
  (state: IDomainCheckReducer, payload: boolean) => ({
    ...state,
    isDomainValid: payload,
  })
);
