export const getTicketDetails = (
  searchData: string,
  lpn: string,
  ticketno: string,
  isPay: boolean,
  lang: string
) => {
  const baseURL: string = process.env.REACT_APP_API_URL as string;
  let url = "";
  if (lpn) {
    url = `${baseURL}api/v1/public/ticket/info/${ticketno}?lpn=${lpn}&language=${lang}`;
  } else {
    const getPay = isPay || false;
    url = `${baseURL}api/v1/public/ticket/info/${searchData}?isPay=${getPay}&language=${lang}`;
  }

  return fetch(url, {
    method: "get",
  }).then((data) => data.json());
};
export const sendTicketChatMessage = (body: any) => {
  console.log("bodyyyyyyyyyyyyyyyyyyyyyyy", body);
  const baseURL: string = process.env.REACT_APP_API_URL as string;
  return fetch(`${baseURL}api/v1/public/dispute/chat`, {
    method: "POST",
    // headers: {
    //   "Content-Type": "multipart/form-data;boundary=MyBoundary",
    // },
    body: body,
  }).then((data) => data.json());
};

// export const getPaymentDetails = async (ticketno: any) => {
//   const baseURL: string = process.env.REACT_APP_API_URL as string;
//   const url = `${baseURL}api/v1/public/payment-receipt/${ticketno.ticketno}/${ticketno.lang}/${ticketno.isMobile}`;
//   return await new Promise((resolve) => {
//     fetch(`${url}`)
//       .then((blobData) => blobData.blob())
//       .then((response) => {
//         console.log("==== response", response);
//         resolve(URL.createObjectURL(response));
//       });
//   });
// };

export const getPaymentDetails = async (ticketno: any) => {
  try {
    const baseURL: string = process.env.REACT_APP_API_URL as string;
    if (!baseURL) throw new Error("Base URL is not defined");
    
    const url = `${baseURL}api/v1/public/payment-receipt/${ticketno.ticketno}/${ticketno.lang}/${ticketno.isMobile}`;
    console.log("Fetch URL:", url);
    
    const response = await fetch(url);
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const blob = await response.blob();
    console.log("==== response", blob);
    return URL.createObjectURL(blob);
    
  } catch (error) {
    console.error("Fetch error:", error);
    throw error; // rethrowing the error after logging
  }
};

export const sendPaymentMail = (body: any) => {
  const baseURL: string = process.env.REACT_APP_API_URL as string;
  return fetch(`${baseURL}api/v1/public/send-payment-mail`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((data) => data.json());
};
