import { call, put, select } from "redux-saga/effects";
import { SagaIterator } from "redux-saga";
import { ISagaAction } from "../types";
import {
  getTicketDetails,
  sendTicketChatMessage,
  getPaymentDetails,
  sendPaymentMail,
} from "../utils/Api/tickets";
import { getFooterDetails } from "../utils/Api/footer";
import { disputeTicket } from "../utils/Api/disputeTicket";
import {
  setTicketDetails,
  ticketDetailsFailed,
  setTicketNumber,
  setFooterDetails,
  setTicketDispute,
  setPaymentInfoDetails,
  setLanguageDetails,
} from "../actions";
import { getSelectedTicketNumber, getTicketData, getselectLanguage} from "../selectors";
import cloneDeep from "lodash/cloneDeep";
import { toast } from "react-toastify";

export const searchTicketData = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const { search, history, lpn, ticketno, isPay } = action.payload;
    const selectedTicketNo = yield select(getSelectedTicketNumber);
    let lang = yield select(getselectLanguage);
    lang = lang && lang !== undefined ? lang : "";
    const response = yield call(getTicketDetails, search, lpn, ticketno, isPay, lang);
    response.data = response.data || [];
    yield put(setTicketDetails(response.data));
    yield put(setLanguageDetails(response.languageData));
    let ticketIndex = 0;
    if (selectedTicketNo) {
      ticketIndex = response.data.findIndex(
        (x: { ticket_no: number }) => x.ticket_no === selectedTicketNo
      );
      if (ticketIndex === -1) ticketIndex = 0;
    }
    if (response.data.length > 0) {
      yield put(setTicketNumber(response.data[ticketIndex].ticket_no));
    } else {
      yield put(setTicketDetails([]));
      toast.error("No data found.");
      history.push("/");
    }
  } catch (error) {
    console.log("ERROR:", error);
    yield put(ticketDetailsFailed());
    // here we will need to show a toaster to show an error
  }
};
export const getFooterData = function* (): SagaIterator {
  try {
    const response = yield call(getFooterDetails);
    response.data = response.data || {};
    console.log("response", response.data);
    yield put(setFooterDetails(response.data));
  } catch (error) {
    console.log("ERROR:", error);
    yield put(ticketDetailsFailed());
    // here we will need to show a toaster to show an error
  }
};

export const ticketDispute_ = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    console.log("action.payload---------------------", action.payload);
    const selectedTicketNo = yield select(getSelectedTicketNumber);
    const response = yield call(
      disputeTicket,
      action.payload.selectedTicket.id
    );
    if (response.success) {
      yield put(setTicketDispute(true));
      action.payload.history.push(`/dispute/${selectedTicketNo}`);
    }
    console.log("response", response.data);
  } catch (error) {
    console.log("ERROR:", error);
    // yield put(ticketDetailsFailed());
  }
};

export const sendTicketChatDetails = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const { ticketNo } = action.payload;
    const obj = action.payload;
    const formData = new FormData();
    Object.keys(obj)
      // .filter((x) => obj[x])
      .map((key) => formData.append(key, obj[key]));
    console.log(formData);

    const response = yield call(sendTicketChatMessage, formData);
    const ticketData = yield select(getTicketData);
    const ticket = cloneDeep(ticketData);
    ticket.forEach((s: any) => {
      if (s.id === ticketNo) {
        s.dispute_chats = [...s.dispute_chats, response.data];
      }
    });
    yield put(setTicketDetails(ticket));
  } catch (error) {
    console.log("ERROR:", error);
    yield put(ticketDetailsFailed());
  }
};

export const getPaymentData = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const { ticketNo, lang, isMobile } = action.payload;
    const response = yield call(getPaymentDetails, {
      ticketno: ticketNo,
      lang: lang,
      isMobile: isMobile,
    });
    yield put(setPaymentInfoDetails(response));
  } catch (error) {
    console.log("ERROR:", error);
    yield put(ticketDetailsFailed());
    // here we will need to show a toaster to show an error
  }
};

export const sendPaymentMailCall = function* (
  action: ISagaAction<any>
): SagaIterator {
  try {
    const { emailToSend, ticket_no, language } = action.payload;
    const response = yield call(sendPaymentMail, {
      emailToSend: emailToSend,
      ticket_no: ticket_no,
      language: language,
    });
    if (response.status === 1) {
      toast.success("Payment Mail send Successfully");
    } else {
      toast.error(response.message);
    }
  } catch (error) {
    console.log("ERROR:", error);
    yield put(ticketDetailsFailed());
    // here we will need to show a toaster to show an error
  }
};
