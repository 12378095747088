import { createAction } from "redux-act";

export const doATicketPayment = createAction<any>("Ticket payment api");
export const ticketPaymentCompleted = createAction<any>(
  "Complete ticket payment"
);
export const setPaymentInfo = createAction<any>("setting payment information");
export const resetInfo = createAction("reset stored information");
export const ticketDispute = createAction<any>("Dispute the ticket");
export const userDetails = createAction<any>("user details");
export const paymentSuccessResponse = createAction<any>(
  "payment response success"
);
