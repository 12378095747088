export const doTicketPayment = (body: any) => {
  const baseURL: string = process.env.REACT_APP_API_URL as string;
  return fetch(
    `${baseURL}api/v1/payment/step1/${body.search}/${body.tokenId}`,
    {
      method: "GET",
    }
  ).then((data) => data.json());
};

export const completeTicketPayment = (
  tokenId: string,
  userDetails: any,
  ticketid: any
) => {
  console.log("payment Api call::::::::::::",userDetails)
  const baseURL: string = process.env.REACT_APP_API_URL as string;
  return fetch(`${baseURL}api/v1/payment/step2/${tokenId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: userDetails?.email,
      name: userDetails?.name,
      ticket_id: ticketid,
    }),
  }).then((data) => data.json())
  .catch((e) => {
      console.log(
        "Enforcement side- payment Api call error::::::::::",e);
    });
};

// export const updatePaymentStatus = (tokenId: string, paidAmount: number) => {
//   const baseURL: string = process.env.REACT_APP_API_URL as string;
//   return fetch(`${baseURL}api/v1/public/ticket/payment_status/${tokenId}`, {
//     method: "PATCH",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({ paid_amount: paidAmount, status: "Paid" }),
//   }).then((data) => data.json());
// };
