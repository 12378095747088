import React from 'react';

export const NotFoundConfig = {
    routes: [
        {
            path: '/not-found',
            component: React.lazy(() => import('./NotFound')),
        },
    ],
};
