import React from "react";
import { Redirect } from "react-router-dom";
import { TicketsSearchConfig } from "../pages/TicketSearch/TicketSearchConfig";
import { NotFoundConfig } from "../pages/NotFound/NotFoundConfig";
import { Dispute } from "pages/Dispute/DisputeConfig";
import { TicketsDetailsConfig } from "../pages/TicketDetails/TicketDetailsConfig";
import { PaymentConfig } from "pages/Pay/PaymentConfig";
import { PrivacyPolicyConfig } from "pages/PrivacyPolicy/PrivacyPolicyConfig";

const routeConfigs = [
  PrivacyPolicyConfig,
  TicketsSearchConfig,
  NotFoundConfig,
  TicketsDetailsConfig,
  PaymentConfig,
  Dispute,
];

const generateRoutesFromConfigs = (configs: any) => {
  let allRoutes: any[] = [];
  configs.forEach((config: any) => {
    allRoutes = [
      ...allRoutes,
      ...config.routes.map((x: any) => ({ ...x, exact: true })),
    ];
  });
  return allRoutes;
};
const routes = [
  ...generateRoutesFromConfigs(routeConfigs),
  {
    path: "*",
    exact: true,
    isPrivate: true,
    component: () => <Redirect to="/not-found" />,
  },
];
export default routes;
