import React, { useEffect } from "react";
import { setFooterDetails, getselectLanguage,getTicketData } from "modules/selectors";
import { getFooterData } from "modules/actions";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Translation } from "react-i18next";
import _i18n from "../TicketSearch/i18n";
import ezIcon from "../../assets/img/ez-logo.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Versions from "../EnforcementVersion/Version.json";

const Footer = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFooterData());
  }, [dispatch]);

  const ticketData = useSelector(getTicketData);
  const footerData_ = useSelector(setFooterDetails);
  const lang = useSelector(getselectLanguage);
  const redirectToPolicy = React.useCallback(() => {
    if (lang === "en") {
      history.push("/privacy-policy");
    } else {
      history.push("/privacy-policy-french");
    }
  }, [history, lang]);

  useEffect(() => {
    _i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <div className={ticketData.length && ticketData[0]?.state === "Close" ? "fixed-bottom fixed-bottom-paid" : "fixed-bottom"}>
      {footerData_ && Object.keys(footerData_).length > 0 && (
        <FooterStyle className="footer d-flex justify-content-between align-items-center py-2 px-3">
          <div>
            {footerData_.footer_logo ? (
              <img
                style={{ height: 25, width: "auto" }}
                src={footerData_.footer_logo}
                alt=""
              />
            ) : null}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: footerData_.footer_text ? footerData_.footer_text : "",
            }}
          />
          <div className="d-flex align-items-center justify-content-center enforce-footer">
            <div>
              <img
                style={{ height: "30px", width: "80px", marginRight: 5 }}
                src={ezIcon}
                alt=""
              />
              ©<Translation>{(t) => <>{t("COPYRIGHT")}</>}</Translation>{" "}
            </div>
            <div>
              {" "}
              <a
                href=" https://locomobiworld.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Translation>{(t) => <>{t("LOCOMOBI.WORLD")}</>}</Translation>{" "}
              </a>
              -
              <Translation>
                {(t) => <>{t("ALL.RIGHTS.RESERVED")}</>}
              </Translation>
            </div>
          </div>
          <div className="footer-link">
            <a
              href="https://locomobiworld.com/terms-and-conditions/"
              className="mr-3"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Translation>{(t) => <>{t("Terms.conditions")}</>}</Translation>
            </a>
            <span
              onClick={redirectToPolicy}
              style={{ cursor: "pointer", color: "#24a5ca" }}
            >
              <Translation>{(t) => <>{t("privacyPolicy")}</>}</Translation>
            </span>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip id={"tooltip-top"} {...props}>
                  Version: {Versions.Version}
                </Tooltip>
              )}
            >
              <i className="fa fa-snowflake ml-3 snowFlake"></i>
            </OverlayTrigger>
          </div>
        </FooterStyle>
      )}
    </div>
  );
};

export default Footer;
const FooterStyle = styled.footer`
  background: #d6d8e9 !important;
  height: 40px !important;
  font-size: 13px;
`;
