import { IStoreState } from "modules/types";

export const getTicketData = (state: IStoreState) =>
  state.ticketDetails.ticketData;

export const getlanguageData = (state: IStoreState) =>
  state.ticketDetails.languageData;

export const isTicketFetched = (state: IStoreState) =>
  state.ticketDetails.fetching;

export const getSelectedTicketNumber = (state: IStoreState) =>
  state.ticketDetails.ticketNumber;

export const setFooterDetails = (state: IStoreState) =>
  state.ticketDetails.footerData;

export const getDisputeStatus = (state: IStoreState) =>
  state.ticketDetails.disputeStatus;

export const getPaymentFetched = (state: IStoreState) =>
  state.ticketDetails.isPayment;

export const getPaymentReceipt = (state: IStoreState) =>
  state.ticketDetails.paymentReceiptData;
