export const submitDisputeForm = (body: any) => {
  const baseURL: string = process.env.REACT_APP_API_URL as string;
  return fetch(`${baseURL}api/v1/dispute`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }).then((data) => data.json());
};
