import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as reducers from 'modules/reducers';
import rootSaga from 'modules/sagas';
import { persistStore, persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';

const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = compose;

if (process.env.REACT_APP_ENVIRONMENT === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    blacklist: ['disputeModal', 'ticketDetails']
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
export const persistor = persistStore(store);
export default store;

if (window.Cypress) {
    window.__store__ = store;
}

sagaMiddleware.run(rootSaga);
