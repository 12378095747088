import React from "react";

export const Dispute = {
  routes: [
    {
      path: "/dispute/:ticketno",
      component: React.lazy(() => import("./Dispute")),
    },
  ],
};
