import { createAction } from "redux-act";

export const fetchTicketDetails =
  createAction<{
    search: string;
    history: any;
    lpn: string;
    ticketno: string;
    isPay?: boolean;
  }>("Fetch ticket data");

export const getFooterData = createAction("Fetch footer data");
export const getPaymentInfoData =
  createAction<{ ticketNo: string; lang: string; isMobile: any }>(
    "Fetch payment data"
  );

export const setPaymentInfoDetails = createAction<any>(
  "set payment data in redux store"
);
export const sendPaymentMail =
  createAction<{ emailToSend: string; ticket_no: string; language: string }>(
    "send mail"
  );

export const setTicketDetails = createAction<any[]>(
  "set ticket data in redux store"
);

export const setLanguageDetails = createAction<any>(
  "set language data in redux store"
);
export const setFooterDetails = createAction<any>(
  "set footer data in redux store"
);

export const ticketDetailsFailed = createAction(
  "Failed to store ticket details"
);

export const ticketChatDetails = createAction<{ ticketNumber: string }>(
  "Fetch ticket chat details"
);

export const setTicketChatDetails = createAction<any[]>(
  "set ticket chat data in redux store"
);

export const setTicketNumber = createAction<any>("set ticket number");

export const sendDisputeMessage = createAction<any>("send dispute message");
