import React from "react";
import styled from "styled-components";
import { Col, Row } from "react-bootstrap";
import { Translation } from "react-i18next";
import _i18n from "../TicketSearch/i18n";
import { useSelector } from "react-redux";
import { getselectLanguage } from "modules/selectors";

const PleaseWait = () => {
  const lang = useSelector(getselectLanguage);

  React.useEffect(() => {
    _i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <div className="login-container">
      <div className="login-card">
        <Card>
          <h2 className="title text-center">
            {" "}
            <Translation>{(t) => <>{t("Please.Wait")}</>}</Translation>
          </h2>
          <Row>
            <Col md="12">
              <div className="info-container">
                <p>
                  <span>
                    <Translation>
                      {(t) => <>{t("Property.Information")}</>}
                    </Translation>
                  </span>
                </p>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};
export default PleaseWait;
const Card = styled.div`
  width: 100%;
`;
